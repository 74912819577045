// 
// authentication.scss
//
.bg-pattern {
    // background-image: url("../../../images/bg.jpg");
    background-size: cover;
    background-position: center;
    background: rgb(34,160,233);
    background: linear-gradient(180deg, rgba(34,160,233,1) 0%, rgba(0,0,0,1) 40%);
    background-repeat: no-repeat;
}

.left-auth-card {
    background-image: url("../../../images/login_lock_graphic.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 500px;
    width: 400px;
    max-width: 100vw;
    padding: 25px;
    display: flex;
    flex-direction: column;
}

.auth-container {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    padding: 200px 0px 0px 0px;
}


.right-auth-card {
    background-color: white;
    padding: 25px;
    width: 400px;
    height: 500px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}

.auth-left-footer {
    align-self: center;
    margin-top: auto;
}

.right-auth-footer {
    align-self: center;
    margin-top: auto;
}
// Hide left card on mobile
@media screen and (max-width: 800px) {
    .auth-left-footer {
        display: none;
    }
    .auth-mobile-container {
        display: flex;
        flex: 2;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        min-height: 100%;
        padding-top: 0px;
        margin: 0px;
    }
    .left-auth-mobile-card {
        width: 100vw;
        height: 30vh;
        text-align: center;
        background-image: url("../../../images/login_lock_graphic.png");
        background-size: cover;
    }
    .right-auth-mobile-card {
        height: 70vh;
        width: 100vw;
        padding: 25px;
        background-color: white;
    }
}

.auth-logo {
    &.logo-light {
        display: $display-none !important;
        height: 30;
        width: 200;
        min-width: 100;
        min-height: 15;
        margin-top:50;
    }

    &.logo-dark {
        display: $display-block !important;
        height: 30;
        width: 200;
        min-width: 100;
        min-height: 15;
        margin-top:50;
    }
}